import React, { useEffect, useState } from "react";
import { useAccount, useSignMessage } from "wagmi";
import { useTranslation } from "react-i18next";
import { ConnectKitButton } from "connectkit";
import CustomButton from "/components/core/ButtonDefault";
import Hidden from "/components/core/labels/Hidden";
import Input from "/components/core/labels/Input";
import Checkbox from "/components/core/labels/Checkbox";
import { useDetectLanguage } from "./hooks/useDetectLanguage";
import { useGetReferral } from "./hooks/useGetReferral";
import { useValidateNickname } from "./hooks/useValidateNickname";
import { useWalletAuth } from "/api/auth/auth";

const generateAuthMessage = () => ({
  timestamp: Math.floor(Date.now() / 1000),
  nonce: crypto.randomUUID(),
});

export default function WalletAuth() {
  const { t } = useTranslation();
  const { address, isConnected } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const [formData, setFormData] = useState({
    name: address ? address.slice(0, 12) : "",
    older: false,
    agree: false,
  });
  const { isNameOk, nameErrors } = useValidateNickname(formData.name);
  const { detectedLanguage } = useDetectLanguage();
  const { btag } = useGetReferral();

  const {
    trigger: handleAuthRequest,
    error: requestError,
    isMutating: isLoading,
  } = useWalletAuth();

  useEffect(() => {
    setFormData((prev) => {
      const name = prev.name || address ? address.slice(0, 12) : "";

      return {
        ...prev,
        name,
      };
    });
  }, [address]);

  const handleAuth = async (e) => {
    try {
      // convert form data to object
      const formValues = Object.entries(
        Object.fromEntries(new FormData(e.target))
      ).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value === "on" ? true : value };
      }, {});

      const message = generateAuthMessage();
      const messageStr = JSON.stringify(message);

      const signature = await signMessageAsync({
        message: messageStr,
        account: address,
      });

      await handleAuthRequest({
        wallet: address,
        signature,
        message: messageStr,
        ...formValues,
      });
    } catch (err) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isConnected) {
      await handleAuth(e);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="form df fd-c">
      <div className="form_content">
        {detectedLanguage && (
          <Hidden name="language_id" value={detectedLanguage.uuid} />
        )}
        {btag && <Hidden name="btag" value={btag} />}
        <Hidden name="page" value="gaming" />
        <Input
          type="text"
          name="name"
          value={formData.name}
          handleChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              name: e.target.value,
            }))
          }
          placeholder={t("registration.labels.username.placeholder")}
          description={t("registration.labels.username.description")}
          autocomplete="off"
          tip={{
            title: t("registration.labels.username.placeholder"),
            tip: t("registration.labels.username.tip"),
          }}
          error={nameErrors}
          isOk={isNameOk}
        />

        <Checkbox
          type="checkbox"
          title={t("registration.labels.age.title")}
          name="older"
          value={formData.older}
          required
          handleChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              older: e.target.checked,
            }))
          }
        />

        <Checkbox
          type="checkbox"
          title={t("registration.labels.agree.title")}
          name="agree"
          value={formData.agree}
          required
          handleChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              agree: e.target.checked,
            }))
          }
        />
      </div>
      <div className="buttons df mr-t-46">
        {!isConnected ? (
          <ConnectKitButton.Custom>
            {({ show }) => (
              <CustomButton
                classname="submit pr as-c"
                def={40}
                onClick={async () => {
                  await show();
                }}
                title={t("wallet.connect")}
              />
            )}
          </ConnectKitButton.Custom>
        ) : (
          <CustomButton
            classname="submit pr as-c"
            def={40}
            type="submit"
            disabled={
              isLoading || !formData.older || !isNameOk || !formData.agree
            }
            title={
              isLoading
                ? t("wallet.authenticating")
                : `${t("wallet.authenticate")} 0x...${address.slice(-4)}`
            }
          />
        )}
      </div>

      {requestError && (
        <div className="error-form mr-t-24 color-neu">
          {requestError?.code
            ? t(`serverErrorCode.${requestError.code}`)
            : requestError?.message
              ? requestError.message
              : t("message.somethingWentWrong")}
        </div>
      )}
    </form>
  );
}
