import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { startScroll, stopScroll } from "/utils/scroll-config";
import Buy from "./wallet-parts/Buy";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import Deposit from "./wallet-parts/Deposit";
import Withdraw from "./wallet-parts/Withdraw";

import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import { usePaymentUUID } from "/api/cashier/generate-payment-uuid";
import { useKycStatus } from "/api/user/kyc-status";
SwiperCore.use([FreeMode]);

const parts = {
  buy: Buy,
  deposit: Deposit,
  withdraw: Withdraw,
};

const WalletPopUp = (props) => {
  const { state, auth } = props;
  const opened = state.getState().showWallet;
  const [isShow, setIsShow] = useState(false);
  const [activeTab, setActiveTab] = useState("buy");
  const axiosClient = useAxiosWithAuth();
  const res = usePaymentUUID(axiosClient);
  const kycStatus = useKycStatus(axiosClient);
  const { t } = useTranslation();

  const tabs = [
    { key: "buy", title: t("wallet.buyTico") },
    { key: "deposit", title: t("wallet.depositTico") },
    { key: "withdraw", title: t("wallet.withdrawTico") },
  ];

  const Component = parts[activeTab];
  const fetchPaymentUuid = async () => {
    const data = await res.trigger();
    if (data?.uuid) {
      window.uuid = data.uuid;
    }
  };

  const onOpenPopUp = () => {
    setIsShow(true);
    stopScroll();
  };

  const onComplete = () => {
    state.setShowWallet(false);
    startScroll();
  };

  const beforeClose = () => {
    return true;
  };

  const onClosePopUp = () => {
    const checkIsCanClose = beforeClose();

    if (!checkIsCanClose) return;

    setIsShow(false);
    gsap.to([], { duration: 0.3, onComplete });
  };

  const handleChangeTab = (g) => {
    setActiveTab(g);
  };

  useEffect(() => {
    if (!opened) return;
    fetchPaymentUuid();
    gsap.to([], { duration: 1, onStart: onOpenPopUp });
    return () => {};
  }, [opened]);

  if (!opened) return <></>;
  return (
    <>
      <div className={`pop_up wallet ${isShow ? "active" : ""}`}>
        <div className="overflow df jc-c ai-st">
          <div className={`content df fd-c`}>
            <h2 className="fixel_36 text-center top-title">
              {t("wallet.title")}
            </h2>
            <Swiper
              slidesPerView={"auto"}
              className="tabs top-tabs df br-20"
              freeMode={true}
            >
              <SwiperSlide className="slide df jc-sb">
                {tabs.map((tab) => (
                  <div
                    onClick={() => handleChangeTab(tab.key)}
                    key={tab.key}
                    className={`def grey type-32 br-14 tab pointer ${tab.key} ${
                      activeTab == tab.key ? "active" : ""
                    }`}
                  >
                    <div
                      className={`icon-24 ar-1 icon_${tab.icon || "activity"}`}
                    ></div>
                    <span className="txt">{tab.title}</span>
                  </div>
                ))}
              </SwiperSlide>
            </Swiper>

            {Component && (
              <Component
                {...{
                  ...props,
                  auth,
                  kycStatus,
                  onClose: onClosePopUp,
                  changeTab: handleChangeTab,
                }}
              />
            )}

            <button
              className="df close close_icon"
              onClick={onClosePopUp}
              title={t("labels.close")}
            ></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletPopUp;
