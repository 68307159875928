import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { gsap, ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";

import FunticoLogo from "/components/core/Logo";
import UnLoginedButton from "/components/core/HeaderNavButtons";
import { useContext } from "react";
import AuthContext from "/context/AuthProvider";
import DelayLink from "/components/core/DelayLink";
import Eye from "/assets/img/icons/eye.svg";
import EyeHide from "/assets/img/icons/eye-hide.svg";
import Wallet from "/assets/img/icons/wallet.svg";

import UserAvatar from "/components/core/UserAvatar";
import TopBanner from "./TopBanner/TopBanner";
import CustomButton from "/components/core/ButtonDefault";
gsap.registerPlugin(ScrollTrigger);

const CashierWidget = ({ state, isWidgetActive, isMobile }) => {
  const { t } = useTranslation();
  const props = {
    title: isMobile ? null : t("layout.header.wallet"),
    def: isMobile ? 24 : 40,
    onClick: () => state.setShowWallet(true),
    classname: "pr wallet",
  };

  const [isWidgetActiveOverride, setIsWidgetActiveOverride] =
    useState(isWidgetActive);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    // if there is a get param called "widget" and it's true, then set isWidgetActiveOverride to true
    const widgetParam = searchParams.get("widget");
    if (widgetParam === "true") {
      console.log("widgetParam", widgetParam);
      setIsWidgetActiveOverride(true);
    }
  }, [searchParams]);

  return isWidgetActiveOverride ? (
    <CustomButton {...props}>{isMobile && <Wallet />}</CustomButton>
  ) : null;
};

export default function Header({ isMobile, openNav, state, isWidgetActive }) {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const headerRef = useRef(null);
  const [balance, setBalance] = useState(false);
  const { pathname } = useLocation();
  const isProfile = pathname.includes("profile");
  const showNavigation = state.getState().showNavigation;

  useEffect(() => {
    const h = headerRef?.current;
    if (!h) return;
    window["header"] = h;
    const resize = new ResizeObserver(() => ScrollTrigger.refresh());
    resize.observe(document.body);
    return () => {
      resize.disconnect();
    };
  }, []);

  useEffect(() => {
    setTimeout(createScrollTrigger, 10);
  }, [showNavigation]);

  const createScrollTrigger = () => {
    if (!headerRef.current) return;
    const header = headerRef.current;

    ScrollTrigger.create({
      trigger: document.body,
      start: `top -5px`,
      // start: `top ${header.clientHeight * -1}px`,
      end: "bottom center",
      onToggle: (e) => header?.classList.toggle("blurred", e.isActive),
    });

    // return { s, ro };
  };
  return (
    <div className="header_wrapper">
      {showNavigation && <TopBanner state={state} />}
      <header className="header" ref={headerRef}>
        <FunticoLogo />
        {showNavigation &&
          (!isMobile ? (
            <UnLoginedButton state={state} auth={auth}>
              <CashierWidget {...{ state, isWidgetActive, isMobile }} />
            </UnLoginedButton>
          ) : (
            <>
              <button
                className="mobile_open br-16 def grey"
                onClick={() => openNav(true)}
              >
                <div className="b df fd-c jc-c">
                  <span></span>
                  <span></span>
                </div>
              </button>

              {auth.token ? (
                isProfile ? (
                  <NavLink
                    to={"/profile"}
                    title={t("layout.header.profile")}
                    end={true}
                  >
                    <UserAvatar {...auth}>
                      {/* <div className="info"></div> */}
                    </UserAvatar>
                  </NavLink>
                ) : (
                  <DelayLink to={"/profile"} title={t("layout.header.profile")}>
                    <UserAvatar {...auth}>
                      {/* <div className="info"></div> */}
                    </UserAvatar>
                  </DelayLink>
                )
              ) : null}
            </>
          ))}
      </header>
      {showNavigation &&
        (isMobile && auth?.token ? (
          <div className="fixed_ballance df jc-sb ai-c">
            <button
              className={`def check br-12 ${!balance ? "" : "hide"}`}
              onClick={(e) => setBalance(!balance)}
            >
              {!balance ? <Eye /> : <EyeHide />}
            </button>
            {balance ? (
              <div className="bal df ai-c">
                <div className="tico_icon ico"></div>
                {auth?.ticoBalance}
              </div>
            ) : null}
            <CashierWidget {...{ state, isWidgetActive, isMobile }} />
          </div>
        ) : null)}
    </div>
  );
}
